import newRelicMetrics from 'BaxterScript/helper/metrics/BaxterNewRelicMetrics';
import { NewRelicError } from 'BaxterScript/helper/metrics/NewRelicError';
import {
  GoogleAdsSlot,
  ImpressionViewableCallback,
  SlotRenderEndedCallback,
  GoogleSlotExternal,
  SlotClickedCallback,
} from 'BaxterScript/types/Slot';
import { Providers } from 'BaxterScript/version/web/config/Providers';
import { NewRelicMetric } from 'BaxterScript/helper/metrics/NewRelicMetric';
import * as Html from 'BaxterScript/helper/browser/Html';
import { parseStyleToObject } from 'BaxterScript/helper/styles/Styles';

export const googleImpressionViewableCallback =
  (
    slot: GoogleAdsSlot,
    external: GoogleSlotExternal,
    parameters: Record<string, unknown>,
    impressionViewableCallback: ImpressionViewableCallback
  ) =>
  (event) => {
    try {
      if (event.slot === external) {
        console.info('[SLOTS][GOOGLEADS][IMPRESSIONCALLBACK]', event.slot.getSlotElementId());
        impressionViewableCallback(slot[Providers.GOOGLE_ADS].state.loadSource as string, slot, parameters);
      }
    } catch (e) {
      console.error('[SLOTS][GOOGLEADS][IMPRESSIONCALLBACK]', e);
      newRelicMetrics.reportError(NewRelicError.GOOGLEADS_IMPRESSION_CALLBACK_ERROR, { message: (e as Error).message });
    }
  };

const trackIframeClick = (
  iframe: HTMLIFrameElement,
  slotClickedCallback: SlotClickedCallback,
  source: string,
  slot: GoogleAdsSlot,
  parameters: Record<string, unknown>
) => {
  console.info(`[SLOTS][GOOGLEADS][TRACKIFRAMECLICK]`, iframe);
  let isHovering = false;

  const eventListeners = {
    mouseenter: () => {
      isHovering = true;
    },
    mouseleave: () => {
      isHovering = false;
    },
    touchstart: () => {
      isHovering = true;
    },
    touchend: () => {
      isHovering = false;
    },
    touchcancel: () => {
      isHovering = false;
    },
  };

  Object.entries(eventListeners).forEach(([event, handler]) => {
    iframe.addEventListener(event, () => {
      try {
        console.debug(`[SLOTS][GOOGLEADS][TRACKIFRAMECLICK] ${event} on iframe area.`);
        handler();
      } catch (e) {
        console.error(`[SLOTS][GOOGLEADS][TRACKIFRAMECLICK][${event}]`, e);
        newRelicMetrics.reportError(NewRelicError.GOOGLEADS_CLICK_CALLBACK_EVENT_LISTINER_ERROR, {
          message: (e as Error).message,
        });
      }
    });
  });
  const blurHandler = () => {
    let preventMultipleClickTrackingRequests = false;
    return () => {
      setTimeout(() => {
        try {
          console.debug('[SLOTS][GOOGLEADS][TRACKIFRAMECLICK] Blur', document.activeElement, iframe, isHovering);
          if (document.activeElement === iframe && isHovering && !preventMultipleClickTrackingRequests) {
            preventMultipleClickTrackingRequests = true;
            console.debug('[SLOTS][GOOGLEADS][TRACKIFRAMECLICK] Iframe has been clicked.');
            slotClickedCallback(source, slot, parameters);
          }
        } catch (e) {
          console.error('[SLOTS][GOOGLEADS][TRACKIFRAMECLICK][BLUR]', e);
          newRelicMetrics.reportError(NewRelicError.GOOGLEADS_CLICK_CALLBACK_ERROR, {
            message: (e as Error).message,
          });
        }
      }, 0);
    };
  };
  const blur = blurHandler();
  slot[Providers.GOOGLE_ADS].state.blurEventListeners.push(blur);
  globalThis.addEventListener('blur', blur);
};

export const googleSlotRenderEndedCallback =
  (
    slot: GoogleAdsSlot,
    external: GoogleSlotExternal,
    parameters: Record<string, unknown>,
    slotRenderEndedCallback: SlotRenderEndedCallback,
    slotClickedCallback: SlotClickedCallback
  ) =>
  (event) => {
    try {
      if (event.slot === external) {
        console.info('[SLOTS][GOOGLEADS][RENDEREDCALLBACK]', slot.containerId, slot.id, event.slot.getSlotElementId());
        if (slot[Providers.GOOGLE_ADS].state.alreadyRemoved) {
          console.debug('[SLOTS][GOOGLEADS][RENDEREDCALLBACK] slot already removed', slot);
          newRelicMetrics.reportMetric(NewRelicMetric.GOOGLEADS_SLOT_ALREADY_REMOVED, {
            place: 'slotRenderEndedCallback',
          });
          return;
        }
        // eslint-disable-next-line no-param-reassign
        slot.params.campaignId = external.getResponseInformation?.()?.campaignId || 0;
        if (!event.isEmpty) {
          const iframe = slot.innerHtmlElement.getElementsByTagName('iframe')[0];
          if (iframe) {
            trackIframeClick(
              iframe,
              slotClickedCallback,
              slot[Providers.GOOGLE_ADS].state.loadSource as string,
              slot,
              parameters
            );
            if (slot[Providers.GOOGLE_ADS].config?.iframe?.css) {
              Html.addStyleToElement(iframe, parseStyleToObject(slot[Providers.GOOGLE_ADS].config.iframe.css));
            }
          }
        }
        slotRenderEndedCallback(
          slot[Providers.GOOGLE_ADS].state.loadSource as string,
          slot,
          event.isEmpty,
          false,
          parameters
        );
      }
    } catch (e) {
      console.error('[SLOTS][GOOGLEADS][RENDEREDCALLBACK]', e);
      newRelicMetrics.reportError(NewRelicError.GOOGLEADS_RENDERED_CALLBACK_ERROR, { message: (e as Error).message });
    }
  };
